<template>
  <section>
    <section class="search-container">
      <!-- <a-row class="tab-text m-t-24">
                <a @click="typeActiveKey = item.key" :class="item.key == typeActiveKey ? 'active' : ''" v-for="item in typeMap" :key="item.key">{{item.name}}</a>
            </a-row>
            <a-row class="tab-text m-t-14">
                <a @click="typeActiveKey = item.key" :class="item.key == typeActiveKey ? 'active' : ''" v-for="item in typeMap" :key="item.key">{{item.name}}</a>
      </a-row>-->
      <a-form layout="inline" class="m-t-14">
        <a-form-item label="投放位置">
          <a-select
            class="search-select"
            placeholder="全部"
            v-model="searchInfo.publicizePosition"
          >
            <a-select-option value>全部</a-select-option>
            <a-select-option
              v-for="item in companyMap"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="设备名称">
          <a-select
            class="search-select"
            placeholder="全部"
            v-model="searchInfo.deviceCode"
          >
            <a-select-option value>全部</a-select-option>
            <a-select-option
              v-for="(item, key) in teamMaplist"
              :key="key"
              :value="item.deviceCode"
              >{{ item.deviceName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="文档类型">
          <a-select
            class="search-select"
            placeholder="全部"
            v-model="searchInfo.contentType"
          >
            <a-select-option :value="0">全部</a-select-option>
            <a-select-option
              v-for="(item, key) in teamMap"
              :key="key"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>

        <a-form-item label="文档标题">
          <a-input
            class="search-input"
            placeholder="请输入"
            v-model="searchInfo.topic"
          />
        </a-form-item>
        <a-form-item label="排序">
          <a-select
            class="search-select"
            placeholder="请选择"
            v-model="searchInfo.sortType"
          >
            <a-select-option value>全部</a-select-option>
            <a-select-option :value="1">倒序</a-select-option>
            <a-select-option :value="0">正序</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="创建时间">
          <a-range-picker
            class="search-range-date"
            v-model="searchInfo.endDate"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" class="m-r-8" @click="search">
            <a-icon type="search" />查询
          </a-button>
          <a-button class="m-r-8" @click="reset">
            <a-icon type="reload" />重置
          </a-button>
          <!-- <a-button class="m-r-8"><a-icon type="export" />导出</a-button> -->
        </a-form-item>
      </a-form>
      <a-row class="right-action-panel m-t-14">
        <router-link class="item" to="/spms/launchPlan/add"
          ><a-button type="primary">添加投放</a-button></router-link
        >
      </a-row>
    </section>

    <section class="list-table-container">
      <a-table
        :bordered="false"
        size="small"
        class="m-t-18"
        :columns="columns"
        :data-source="listMap"
        :pagination="{
          total,
          hideOnSinglePage: true,
          pageSize: pageSize,
          showTotal: (total) => `共${total}条`,
          size: 'default',
          showQuickJumper: true,
          showSizeChanger: false,
        }"
        @change="changePage"
      >
        <!-- 1-项目概览,2-宣传快讯,3-通知公告,4-LED屏 -->
        <span slot="publicizePosition" slot-scope="text">{{
          text == 1
            ? "项目概览"
            : text == 2
            ? "宣传快讯"
            : text == 3
            ? "通知公告"
            : "LED屏"
        }}</span>
        <span slot="deviceType" slot-scope="text">{{
          text == 1 ? "门禁设备" : "考勤设备"
        }}</span>
        <span slot="contentType" slot-scope="text">{{
          text == 1 ? "文本" : text == 2 ? "视频" : "图片"
        }}</span>
        <span
          slot="inOutType"
          slot-scope="text"
          :class="text == 1 ? 'list-status-in' : 'list-status-out'"
          >{{ text == 1 ? "进入" : "离开" }}</span
        >
        <a-row slot="photoUrl" slot-scope="text">
          <previewImage :data="text" :size="40" />
        </a-row>

        <a slot="action" slot-scope="text, list">
          <!-- <router-link
            class="m-r-10"
            :to="{ path: '/pcms/salary/setting/edit', query: { id: list.id } }"
            >查看</router-link
          > -->
          <span @click="detail(list)" style="margin-right: 10px">查看</span>
          <a-popconfirm title="确定删除?" @confirm="deleteItem(list.id)"
            >删除</a-popconfirm
          >
        </a>
      </a-table>
    </section>
  </section>
</template>

<script>
const columns = [
  { title: "序号", dataIndex: "key", align: "center", key: "key" },
  {
    title: "投放位置",
    dataIndex: "publicizePosition",
    align: "center",
    key: "publicizePosition",
    scopedSlots: { customRender: "publicizePosition" },
  },
  {
    title: "设备名称",
    dataIndex: "deviceName",
    align: "center",
    key: "deviceName",
  },
  {
    title: "文档类型",
    dataIndex: "contentType",
    align: "center",
    key: "contentType",
    scopedSlots: { customRender: "contentType" },
  },
  {
    title: "文档标题",
    dataIndex: "topic",
    align: "center",
    key: "topic",
  },
  { title: "排序", dataIndex: "sort", align: "center", key: "sort" },
  {
    title: "创建时间",
    dataIndex: "createTime",
    align: "center",
    key: "createTime",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
import previewImage from "@/components/previewImage";
export default {
  data() {
    return {
      columns,
      pageNum: 1,
      pageSize: 9,
      total: 0,
      listMap: [],
      searchInfo: {
        endDate: [],
        contentType: 0,
      },
      typeMap: [
        { name: "全部", key: 0 },
        { name: "设备名称1", key: 1 },
        { name: "设备名称2", key: 2 },
      ],
      typeActiveKey: 0,
      companyMap: [
        { id: 1, name: "项目概览" },
        { id: 2, name: "宣传快讯" },
        { id: 3, name: "通知公告" },
        { id: 4, name: "LED屏" },
      ],
      teamMap: [
        { id: 1, name: "文本" },
        { id: 2, name: "视频" },
        { id: 3, name: "图片" },
      ],
      teamMaplist: [],
    };
  },
  components: { previewImage },
  created() {
    this.init();
  },
  mounted() {
    this.queryPersonnelFaceCollectionLog();
  },
  methods: {
    //   /publicize/contentSet/removePublicizePlan
    deleteItem(id) {
      this.$api.removePublicizePlan({id:id}).then((res) => {
        if (res.code === 200) {
            this.$message.success('删除成功')
            this.queryPersonnelFaceCollectionLog();
        }
      });
    },
    detail(list) {
      localStorage.setItem("navDetail", JSON.stringify(list));
      this.$router.push({ path: "/spms/launchPlan/detail" });
    },
    init() {
      this.getPublicizeDeviceList();
    },
    getPublicizeDeviceList() {
      this.$api.getPublicizeDeviceList2().then((res) => {
        if (res.code === 200) {
          this.teamMaplist = res.data || [];
        }
      });
    },
    //考勤列表
    queryPersonnelFaceCollectionLog() {
      let params = {
        current: this.pageNum,
        size: this.pageSize,
        publicizePosition: this.searchInfo.publicizePosition,
        contentType: this.searchInfo.contentType,
        topic: this.searchInfo.topic,
        sortType: this.searchInfo.sortType,
        deviceCode: this.searchInfo.deviceCode,
        startDateTime: this.searchInfo.endDate.length
          ? this.searchInfo.endDate[0].format("YYYY-MM-DD")
          : "",
        endDateTime: this.searchInfo.endDate.length
          ? this.searchInfo.endDate[1].format("YYYY-MM-DD")
          : "",
      };
      this.$api.publicizeShowPage(params).then((res) => {
        if (res.code === 200) {
          this.listMap = (res.data && res.data.records) || [];
          this.total = (res.data && res.data.total) || 0;
          this.listMap.forEach((item, ind) => {
            item.key = this.pageSize * (this.pageNum - 1) + ind + 1;
          });
        }
      });
    },
    reset() {
      this.searchInfo = {
        endDate: [],
      };
      this.pageNum = 1;
      this.queryPersonnelFaceCollectionLog();
    },
    search() {
      this.pageNum = 1;
      this.queryPersonnelFaceCollectionLog();
    },
    //分页
    changePage(p) {
      this.pageNum = p.current;
      this.queryPersonnelFaceCollectionLog();
    },
  },
};
</script>

<style lang="scss" scoped>
.list-status-in {
  color: #52c41a;
  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 8px;
    background: #52c41a;
  }
}
.list-status-out {
  color: #e25745;
  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 8px;
    background: #e25745;
  }
}
</style>